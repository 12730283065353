/** 
font-family: 'Marcellus', serif;
font-family: 'Marcellus SC', serif;
font-family: 'Playfair Display', serif;
font-family: 'Playfair Display SC', serif;
font-family: 'Spectral', serif;
font-family: 'Spectral SC', serif;
*/

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 12px;
  background-size: cover;
  position: relative;
}

.Card {
  width: 380px;
  height: 220px;
  /* prettier-ignore */
  box-shadow: 
    0 4px 16px rgba(0, 0, 0, 0.4),
    inset 0 1px 1px rgba(255, 255, 255, 1), 
    inset 0 -1px 2px rgba(0, 0, 0, 0.8);
  font-size: 14px;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-color: hsl(63, 20%, 92%);
  text-shadow: 0px 1px 1px rgba(255, 255, 255, 1),
    0px -1px 2px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  color: #333;
  border-radius: 2px;
  background-size: cover;
}

.Card .inner {
  position: absolute;
  inset: 16px;
}

.Card.three {
  font-family: 'Spectral', serif;
}
.Card.three .name,
.Card.three .company {
  font-family: 'Spectral SC', serif;
}
.Card.two {
  font-family: 'Hubballi', serif;
  text-transform: uppercase;
  font-size: 15px;
  text-shadow: 0px 1px 1px rgba(255, 255, 255, 1),
    0px 0px 2px rgba(0, 0, 0, 0.5);
  color: #444;  
}
.Card.two .name,
.Card.two .company {
  font-family: 'Hubballi', serif;
}
.Card.one {
  font-family: 'Playfair Display', serif;
}
.Card.one .name,
.Card.one .company {
  font-family: 'Playfair Display SC', serif;
}

.Card .company {
  font-size: 13px;
  line-height: 16px;
  position: absolute;
  top: 0;
  right: 0;
}

.Card .company h1,
.Card .company h2 {
  font-size: 1em;
  margin: 0;
}
.Card .company h2 {
  font-size: 0.9em;
}

.Card .email {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 1.2em;
}
.Card .email span {
  margin: 0 2px;
}
.Card .location {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.Card .identity {
  font-size: 1.6em;
  line-height: 1.2em;
  position: relative;
}
.Card .name {
  letter-spacing: 1px;
}
.Card .title {
  font-size: 0.7em;
}

@media screen and (max-width: 900px) {
  .App {
    flex-direction: column;
  }
}
